import React from 'react';
import './Loader.css'; // Y�kleme g�stergesi i�in CSS stilini i�eren dosya

const Loader = () => (
    <div className="loader-container">
        <div className="loader"></div>
    </div>
);

export default Loader;
