import './App.css';
import Main from './Main';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';


function App() {
  return (
    <DndProvider backend={HTML5Backend}>

      <div className="d-flex h-100 justify-content-center align-items-center" style={{ backgroundColor: '#e4e4e4' }}>
        <Main />
      </div>

    </DndProvider>
  );
}

export default App;
