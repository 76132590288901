import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const PdfNavigation = ({ currentPage, setCurrentPage, numPdfPages }) => {
    const goToPrevPage = () => {
        setCurrentPage((prevCurrentPage) => Math.max(prevCurrentPage - 1, 1));
    };

    const goToNextPage = () => {
        setCurrentPage((prevCurrentPage) => Math.min(prevCurrentPage + 1, numPdfPages));
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0px 0' }}>
            <button className='btn btn-sm btn-secondary' onClick={goToPrevPage} disabled={currentPage === 1}>
                <FontAwesomeIcon icon={faArrowLeft} /> {/* Önceki sayfa simgesi */}
            </button>
            <span style={{ margin: '0 10px' }}>
                {currentPage} / {numPdfPages}
            </span>
            <button className='btn btn-sm btn-secondary' onClick={goToNextPage} disabled={currentPage === numPdfPages}>
                <FontAwesomeIcon icon={faArrowRight} /> {/* Sonraki sayfa simgesi */}
            </button>
        </div>
    );
};

export default PdfNavigation;
