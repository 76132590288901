import React, { useRef, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const DrawingTool = ({ updatePayload, pdfRef, currentPage, numPdfPages, saveSignatureToDatabase, selectedIndex }) => {
    const canvasRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const language = queryParams.get('language');
    const [color, setColor] = useState('black'); // Renk için başlangıç durumu
    const [thickness, setThickness] = useState(1); // Kalınlık için başlangıç durumu


    // Dil koduna göre metinleri ayarla
    let texts;
    switch (language) {
        case '1': // İngilizce
            texts = {
                send: 'Send',
                save: 'Save',
                drawsignature: 'Draw your signature.',
                clear: 'Clear'
            };
            break;
        case '2': // Almanca
            texts = {
                send: 'Senden',
                save: 'Speichern',
                drawsignature: 'Zeichnen Sie Ihre Unterschrift.',
                clear: 'Löschen'
            };
            break;
        default: // Varsayılan dil İngilizce
            texts = {
                send: 'Send',
                save: 'Save',
                drawsignature: 'Draw your signature.',
                clear: 'Clear'
            };
    }

    const clearCanvas = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Canvas boyutlarına göre tüm içeriği temizle
    };

    useEffect(() => {
        //console.log(pdfRef);
        if (pdfRef.current) {
            const rect = pdfRef.current.getBoundingClientRect();
            //console.log(rect);
            //console.log("deneme")
            // Burada `rect` ile istediğiniz işlemleri yapabilirsiniz
        }
    }, [pdfRef]); // Boş bağımlılık dizisi, etkinin yalnızca bileşen mount edildiğinde çalışmasını sağlar

    const startDrawing = (e) => {
        e.preventDefault(); // Varsayılan olayı engelle, bu sayede ekran kaydırma engellenir.
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.lineWidth = thickness; // Çizim kalınlığını ayarla
        ctx.strokeStyle = color; // Çizim rengini ayarla
        ctx.beginPath();
        let clientX = e.clientX || e.touches[0].clientX;
        let clientY = e.clientY || e.touches[0].clientY;
        const rect = canvas.getBoundingClientRect();
        ctx.moveTo(clientX - rect.left, clientY - rect.top);
        setIsDrawing(true);
    };

    const breakDrawing = () => {
        setIsDrawing(false);
    };

    const draw = (e) => {
        if (!isDrawing) return;
        e.preventDefault();
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let clientX = e.clientX || e.touches[0].clientX;
        let clientY = e.clientY || e.touches[0].clientY;
        const rect = canvas.getBoundingClientRect();
        ctx.lineTo(clientX - rect.left, clientY - rect.top);
        ctx.stroke();
    };

    const saveSignatureToDatabase2 = () => {
        const canvas = canvasRef.current;

        const imageSrc = canvas.toDataURL('image/png');

        saveSignatureToDatabase(imageSrc, 0);
    };
    const handleTouchStart = (e) => {
        if (e.target === canvasRef.current) {
            e.preventDefault();
            startDrawing(e);
        }
    };

    const handleTouchMove = (e) => {
        if (e.target === canvasRef.current) {
            e.preventDefault();
            draw(e);
        }
    };

    const handleTouchEnd = (e) => {
        if (e.target === canvasRef.current) {
            e.preventDefault();
            breakDrawing();
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        // Olay dinleyicileri ekleyin
        canvas.addEventListener('touchstart', handleTouchStart, { passive: false });
        canvas.addEventListener('touchmove', handleTouchMove, { passive: false });
        canvas.addEventListener('touchend', handleTouchEnd, { passive: false });

        // Component unmount edildiğinde olay dinleyicilerini temizleyin
        return () => {
            canvas.removeEventListener('touchstart', handleTouchStart);
            canvas.removeEventListener('touchmove', handleTouchMove);
            canvas.removeEventListener('touchend', handleTouchEnd);
        };
    }, []);

    const finishDrawing = () => {
        setIsDrawing(false);
        const canvas = canvasRef.current;
        // Çizim tamamlandığında, canvas içeriğini bir resme dönüştür
        const imageSrc = canvas.toDataURL('image/png');
        // Resmi ve pozisyon bilgilerini updatePayload fonksiyonu ile Main componentine gönder
        if (pdfRef.current) {
            const rect = pdfRef.current.getBoundingClientRect();
            // `rect` ile işlemlerinizi yapın

            const relativeLeft = rect.left; // Örnek bir hesaplama, gerçekte nasıl ayarlamak istediğinize bağlı
            const relativeTop = rect.top; // Örnek bir hesaplama, gerçekte nasıl ayarlamak istediğinize bağlı
            // console.log(relativeLeft);

            updatePayload({
                id: uuidv4(),
                type: 'DRAWING',
                src: imageSrc,
                left: relativeLeft, // Başlangıç konumu, gerçek uygulamada kullanıcı tarafından belirlenebilir
                top: relativeTop, // Başlangıç konumu, gerçek uygulamada kullanıcı tarafından belirlenebilir
                width: 150, // Çizim genişliği, gerçek uygulamada kullanıcı tarafından belirlenebilir
                page: currentPage,
                selectedIndex: selectedIndex
            });
        }
        // Canvas'ı temizle
        // canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    };

    const finishDrawingAll = () => {
        setIsDrawing(false);
        const canvas = canvasRef.current;
        // Çizim tamamlandığında, canvas içeriğini bir resme dönüştür
        const imageSrc = canvas.toDataURL('image/png');
        // Resmi ve pozisyon bilgilerini updatePayload fonksiyonu ile Main componentine gönder
        if (pdfRef.current) {
            const rect = pdfRef.current.getBoundingClientRect();
            // `rect` ile işlemlerinizi yapın

            const relativeLeft = rect.left; // Örnek bir hesaplama, gerçekte nasıl ayarlamak istediğinize bağlı
            const relativeTop = rect.top; // Örnek bir hesaplama, gerçekte nasıl ayarlamak istediğinize bağlı
            // console.log(relativeLeft);

            // Her bir sayfa için çizimi ekleyin
            for (let page = 1; page <= numPdfPages; page++) {
                console.log(page)
                updatePayload({
                    id: uuidv4(), // Her öğe için benzersiz bir id oluşturun
                    type: 'DRAWING',
                    src: imageSrc,
                    left: relativeLeft, // Başlangıç konumu, gerçekte nasıl ayarlamak istediğinize bağlı
                    top: relativeTop, // Başlangıç konumu, gerçekte nasıl ayarlamak istediğinize bağlı
                    width: 150, // Çizim genişliği, gerçek uygulamada kullanıcı tarafından belirlenebilir
                    page: page // Her sayfa için çizim
                });
            }
        }
        // Canvas'ı temizle
        // canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    };

    const renderColorOptions = () => {
        const colors = ['black', 'red', 'blue'];
        return colors.map((item) => (
            <button
                key={item}
                onClick={() => setColor(item)}
                style={{
                    backgroundColor: item,
                    borderRadius: '50%',
                    width: '30px',
                    height: '30px',
                    border: color === item ? '2px solid #FFF' : '1px solid #DDD',
                    margin: '5px'
                }}
            />
        ));
    };

    return (
        <div>
            <canvas
                ref={canvasRef}
                width="250"
                height="150"
                onMouseDown={startDrawing}
                onTouchStart={startDrawing}
                onMouseMove={draw}
                onTouchMove={draw}
                onMouseUp={breakDrawing}
                onTouchEnd={breakDrawing}
                onTouchCancel={breakDrawing}
                //onMouseUp={finishDrawing}
                //onMouseOut={finishDrawing}
                style={{ border: '2px solid black' }}
            />
            <p>{texts.drawsignature}</p>
            {renderColorOptions()}<br />
            <div>
                <label>
                    <input
                        type="range"
                        min="1"
                        max="10"
                        value={thickness}
                        onChange={(e) => setThickness(parseInt(e.target.value, 10))}
                        style={{ width: '100%' }}
                    />
                </label>
            </div>
            <button className='btn btn-secondary m-1' onClick={finishDrawing}>{texts.send}</button>
            <button className='btn btn-secondary m-1' onClick={saveSignatureToDatabase2}>{texts.save}</button>
            <button className='btn btn-secondary' onClick={clearCanvas}>{texts.clear}</button>
            {/*<button className='btn btn-success' onClick={finishDrawingAll}>Send to All Pages</button>*/}
        </div>
    );
};

export default DrawingTool;
