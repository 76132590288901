import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const ImageUploader = ({ updatePayload, pdfRef, currentPage, saveSignatureToDatabase, numPdfPages, selectedIndex }) => {
    const [preview, setPreview] = useState('');
    const queryParams = new URLSearchParams(window.location.search);
    const language = queryParams.get('language');

    // Dil koduna g�re metinleri ayarla
    let texts;
    switch (language) {
        case '1': // �ngilizce
            texts = {
                send: 'Send',
                save: 'Save',
                saveasstamp: 'Save As Stamp'
            };
            break;
        case '2': // Almanca
            texts = {
                send: 'Senden',
                save: 'Speichern',
                saveasstamp: 'Als Stempel speichern'
            };
            break;
        default: // Varsay�lan dil �ngilizce
            texts = {
                send: 'Send',
                save: 'Save',
                saveasstamp: 'Save As Stamp'
            };
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpload = () => {
        if (preview && pdfRef.current) {
            const rect = pdfRef.current.getBoundingClientRect();
            const relativeLeft = rect.left;
            const relativeTop = rect.top;

            updatePayload({
                id: uuidv4(),
                type: 'IMAGE',
                src: preview,
                left: relativeLeft,
                top: relativeTop,
                width: 150,
                page: currentPage,
                selectedIndex: selectedIndex
            });
        }
    };

    const handleSaveAsStamp = () => {
        if (preview) {
            saveSignatureToDatabase(preview, 1); // �kinci parametre olarak '1' g�ndererek bu i�lemin bir m�h�r oldu�unu belirtiyoruz
        }
    };

    return (
        <div>
            <input type="file" accept="image/png, image/jpeg" onChange={handleImageChange} />
            {preview && (
                <div>
                    <img className='mb-2 mt-2' src={preview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                    <button className='btn btn-primary m-1' onClick={handleSaveAsStamp}>{texts.saveasstamp}</button>
                    <button className='btn btn-success' onClick={handleUpload}>{texts.send}</button>
                </div>
            )}
        </div>
    );
};

export default ImageUploader;
