import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDrop } from 'react-dnd';
import DraggableImage from './Components/DraggableImage';
import DraggableText from './Components/DraggableText';
import Drawing from './Components/Drawing';
import ImageUploader from './Components/ImageUploader';
import TextTool from './Components/TextTool';
import DrawingTool from './Components/DrawingTool';
import PdfNavigation from './Components/PdfNavigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faTrashAlt, faCheck, faTimes, faExclamation, faQuestion, faPencilAlt, faImage, faFont, faSignature, faStamp, faTools } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import Loader from './Components/Loader'; // Yukarıda tanımlanan Loader bileşenini import et

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const apiUrl = process.env.REACT_APP_API_URL; // .env dosyasından URL'yi al

const Main = () => {
    const [numPdfPages, setNumPdfPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [scaleValue, setScaleValue] = useState(1.0);
    const [payload, setPayload] = useState([]);
    const pdfRef = useRef(null);
    const [activeTab, setActiveTab] = useState("signature"); // Varsayılan olarak "drawing" tabını aktif yap
    const [activeElementId, setActiveElementId] = useState(null);
    const [signatureUrl, setSignatureUrl] = useState(null); // Başlangıç değeri null olarak ayarlanır
    const [stamps, setStamps] = useState([]);
    const [pdfFileUrls, setPdfFileUrls] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pdfLoaded, setPdfLoaded] = useState(false);
    const [signatureLoaded, setSignatureLoaded] = useState(false);
    const [canvas, setCanvas] = useState({ width: 100, height: 100 });
    const [documents, setDocuments] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    let texts;

    // URL'den documents'u al ve decode et
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const base64Documents = queryParams.get('payload');

        if (base64Documents) {
            const decodedDocuments = base64Decode(base64Documents);
            if (decodedDocuments) {
                console.log(decodedDocuments);
                setDocuments(decodedDocuments);
            }
        }
    }, []);

    /*const queryParams = new URLSearchParams(window.location.search);
    const language = queryParams.get('language');*/
    texts = {
        sign: 'Sign',
        draw: 'Draw',
        upload: 'Upload',
        text: 'Text',
        signatureempty: 'No saved signature found. Please save a signature first from draw or upload tabs.',
        send: '>>',
        finish: 'Finish',
        stamp: 'Stamps',
        tools: 'Tools',
        delete: 'Delete'
    };
    // Dil koduna göre metinleri ayarla
    useEffect(() => {
        console.log(documents);
        if (documents.length > 0) {
            switch (documents[0].language) {
                case '1': // İngilizce
                    texts = {
                        sign: 'Sign',
                        draw: 'Draw',
                        upload: 'Upload',
                        text: 'Text',
                        signatureempty: 'No saved signature found. Please save a signature first from draw or upload tabs.',
                        send: '>>',
                        finish: 'Finish',
                        stamp: 'Stamps',
                        tools: 'Tools',
                        delete: 'Delete'
                    };
                    break;
                case '2': // Almanca
                    texts = {
                        sign: 'Unterschreiben',
                        draw: 'Zeichnen',
                        upload: 'Hochladen',
                        text: 'Text',
                        signatureempty: 'Keine gespeicherte Signatur gefunden. Bitte speichern Sie zuerst eine Signatur über die Registerkarten Zeichnen oder Hochladen.',
                        send: '>>',
                        finish: 'Fertigstellen',
                        stamp: 'Stamps',
                        tools: 'Tools',
                        delete: 'Delete'
                    };
                    break;
                default: // Varsayılan dil İngilizce
                    texts = {
                        sign: 'Sign',
                        draw: 'Draw',
                        upload: 'Upload',
                        text: 'Text',
                        signatureempty: 'No saved signature found. Please save a signature first from draw or upload tabs.',
                        send: '>>',
                        finish: 'Finish',
                        stamp: 'Stamps',
                        tools: 'Tools',
                        delete: 'Delete'
                    };
            }
        }
    }, [documents])


    function dataURLToBlob(dataUrl) {
        const byteString = atob(dataUrl.split(',')[1]);
        const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    /*useEffect(() => {
        fetchSignature();
    }, []);

    const fetchSignature = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        const uid = queryParams.get('uid');

        try {
            const response = await fetch(`https://localhost:7208/PdfElement/GetSignature?token=${encodeURIComponent(token)}&uid=${encodeURIComponent(uid)}`);
            if (!response.ok) {
                throw new Error('Signature could not be fetched');
            }
            const signatureBytes = await response.json();
            const blob = new Blob([new Uint8Array(signatureBytes)], { type: 'image/png' });
            const signatureUrl = URL.createObjectURL(blob);
            setSignatureUrl(signatureUrl);
            
        } catch (error) {
            console.error('Error fetching signature:', error);
        }
    };*/

    useEffect(() => {
        // Mesaj dinleyicilerini kurma
        function handleMessages(event) {
            if (event.data === 'CancelButtonClicked') {
                console.log('Kullanıcı kaydetmeden çıkmak istiyor. Temizlik işlemleri yapılıyor...');
                // Temizlik işlemleri burada yapılabilir
            }
            if (event.data === 'OkButtonClicked') {
                console.log('İşlemler tamamlandı. Finish mesajı gönderiliyor...');

                document.getElementById('finish_button').click();
                // Asenkron işlemi burada başlat
                // sendPdfWithElementsAsync(pdfFileUrl, payload);
            }
        }

        // Asenkron işlem için ayrı bir fonksiyon
        /*async function sendPdfWithElementsAsync(pdfFileUrl, payload) {
            await sendPdfWithElements(pdfFileUrl, payload);
        }*/

        window.addEventListener('message', handleMessages);

        return () => {
            window.removeEventListener('message', handleMessages);
        };
    }, []);  // Bağımlılıkları unutmayın, gerekirse ekleyin.

    // Finish butonuna tıklandığında çağrılacak fonksiyon
    /*const handleFinish = () => {
        console.log('İşlemler tamamlandı. Finish mesajı gönderiliyor...');
        window.parent.postMessage('FinishButtonClicked', '*');
    };

    // Abort butonuna tıklandığında çağrılacak fonksiyon
    const handleAbort = () => {
        console.log('Kullanıcı iptal etti. Abort mesajı gönderiliyor...');
        window.parent.postMessage('AbortButtonClicked', '*');
    };*/

    useEffect(() => {
        if (documents.length > 0) {

            const fetchSignatureAndStamps = async () => {
                /*const queryParams = new URLSearchParams(window.location.search);
                const token = queryParams.get('token');
                const uid = queryParams.get('uid');*/
                const token = documents[selectedIndex].token;
                const uid = documents[selectedIndex].uid;

                // İmza için API isteği
                const signatureResponse = await fetch(`${apiUrl}/PdfElement/GetSignature?token=${encodeURIComponent(token)}&uid=${encodeURIComponent(uid)}&isstamp=0`);
                setSignatureLoaded(true);
                console.log('signature loaded');
                const signatureData = await signatureResponse.json();
                console.log(signatureData[0].signatureImage);
                // Bu kısımda, API'den gelen yanıtın doğrudan bir resim olduğunu varsayıyoruz.
                // Eğer API yanıtı da bir nesne içinde gelirse, uygun anahtarı kullanarak (örneğin signatureData.SignatureImage) çıkarabilirsiniz.
                const signatureImageUrl = `data:image/png;base64,${signatureData[0].signatureImage}`;
                setSignatureUrl(signatureImageUrl);

                // Mühürler için API isteği
                const stampsResponse = await fetch(`${apiUrl}/PdfElement/GetSignature?token=${encodeURIComponent(token)}&uid=${encodeURIComponent(uid)}&isstamp=1`);
                const stampsData = await stampsResponse.json(); // Mühürler bir dizi olarak döneceği için json formatında alınır
                const stamps = stampsData.map(({ signatureImage, id }) => ({
                    imageUrl: `data:image/png;base64,${signatureImage}`,
                    id: id
                }));
                setStamps(stamps); // Mühürlerin URL'leri ve ID'leri saklanır
            };

            fetchSignatureAndStamps();
        }
    }, [activeTab, documents]);

    const saveSignatureToDatabase = async (signatureDataUrl, isStamp) => {
        const blob = dataURLToBlob(signatureDataUrl);
        /*const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        const uid = queryParams.get('uid');*/

        const token = documents[selectedIndex].token;
        const uid = documents[selectedIndex].uid;

        const formData = new FormData();
        formData.append("signatureFile", blob); // Sadece dosya FormData'ya eklenir

        // URL'e token ve uid eklenir
        const url = `${apiUrl}/PdfElement/UpdateSignature?token=${encodeURIComponent(token)}&uid=${encodeURIComponent(uid)}&isstamp=${isStamp}`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData, // Content-Type otomatik olarak multipart/form-data olarak ayarlanır
            });
            if (response.ok) {
                console.log('Signature saved successfully');
                if (isStamp == 0)
                    setActiveTab("signature");
                else
                    setActiveTab("stamps");
            } else {
                console.error('Failed to save signature', await response.text());
            }
        } catch (error) {
            console.error('Error saving signature:', error);
        }
    };

    useEffect(() => {
        const handleWindowClick = (event) => {
            // Tıklanan elementin `data` attribute'unu kontrol et
            if (!event.target.closest('[data-draggable="item"]')) {
                setActiveElementId(null);
            }
        };

        // Tüm pencereye tıklama olayı dinleyicisi ekle
        window.addEventListener('click', handleWindowClick);

        // Component unmount olduğunda dinleyiciyi kaldır
        return () => {
            window.removeEventListener('click', handleWindowClick);
        };
    }, []);

    useEffect(() => {
        console.log(activeElementId);
    }, [activeElementId]);

    const testLocations = async () => {
        console.log(payload)
        const rect = pdfRef.current.getBoundingClientRect();
        console.log(rect);
        // `rect` ile işlemlerinizi yapın

        const relativeLeft = rect.left; // Örnek bir hesaplama, gerçekte nasıl ayarlamak istediğinize bağlı
        const relativeTop = rect.top; // Örnek bir hesaplama, gerçekte nasıl ayarlamak istediğinize bağlı

        console.log(relativeLeft, relativeTop)
    }

    // Base64 decode işlemi
    const base64Decode = (base64String) => {
        try {
            const jsonString = atob(base64String);
            return JSON.parse(jsonString);
        } catch (error) {
            console.error('Error decoding base64:', error);
            return null;
        }
    };

    const sendPdfWithElements = async (elements) => {
        setIsLoading(true);
        //console.log(elements);

        for (const [index, doc] of documents.entries()) {
            // selectedIndex state'ini döküman index'ine göre ayarla
            console.log(index);
            console.log(doc);
            if (documents.length > 1) {
                setSelectedIndex(index);
                await new Promise(resolve => setTimeout(resolve, 1000)); // State değişimini beklemek için küçük bir gecikme ekliyoruz

            }

            const {
                token,
                RequestedSignedInCompanyCode,
                RequestedRoleId,
                Code,
                Barcode,
                FileContent,
                CategoryFK,
                RubricCategoryFK,
                Comment,
                DocumentTypeFK,
                OriginFileName,
                FileUrl,
                IsDownload,
                Description,
                StatusFK,
                TypeFK,
                InvHeaderCode,
                ProjectCode,
                ProjectInfoRequestCode,
                PsdActivityCode,
                PsdScheduleCode,
                QtnHeaderCode,
                ReqHeaderCode,
                RfqHeaderCode,
                PrjChangeCode,
                QtoHeaderCode,
                StructureCode,
                ExpirationDate,
                ItwoSiteId,
                FileArchiveDocFK,
                CompanyCode,
                UserDataLanguage,
                ProjectDocumentId,
                CommentText,
                LogOptions,
                uid,
                FileArchiveDocId,
                Compress,
            } = doc;

            const RequestedCompanyId = 1;
            const RequestedPermissionClientId = 1;
            const RequestedSignedInCompanyId = 1;
            //const pdfBlobUrl = pdfFileUrls[index];
            //const responseb = await fetch(pdfBlobUrl);
            //const pdfBlob = await responseb.blob();

            const formData = new FormData();
            //const pdfFile = new File([pdfBlob], "document.pdf", { type: "application/pdf" });
            //const dummyBlob = new Blob([], { type: 'application/pdf' });

            // Geçici bir dosya oluştur
            //const pdfFileTest = new File([dummyBlob], "doc1.pdf", { type: 'application/pdf' });
            //formData.append("pdf", pdfFile);

            // pdfRef ve canvas işleme
            if (pdfRef && pdfRef.current) {
                const canvas = pdfRef.current.querySelector('canvas');
                if (canvas) {
                    const rect = pdfRef.current.getBoundingClientRect();
                    const absoluteLeft = rect.left + window.scrollX;
                    const absoluteTop = rect.top + window.scrollY;

                    const rectWidth = canvas.width;
                    const rectHeight = canvas.height;
                    let zoomLevel = getZoomLevel();

                    formData.append("elementsJson", JSON.stringify(payload.filter((a) => (a.selectedIndex === index))));
                    formData.append("canvasLeft", absoluteLeft);
                    formData.append("canvasTop", absoluteTop);
                    formData.append("canvasWidth", rectWidth);
                    formData.append("canvasHeight", rectHeight);
                    formData.append("zoomLevel", zoomLevel); 
                    console.log(Compress);
                    let CompressValue = 0;
                    if (Compress === undefined || Compress === 1)
                        CompressValue = 1;
                    else if (Compress === 0)
                        CompressValue = 0;

                    const queryString = `token=${encodeURIComponent(token)}&uid=${encodeURIComponent(uid)}&requestedSignedInCompanyCode=${RequestedSignedInCompanyCode}&requestedRoleId=${RequestedRoleId}` +
                        `&Code=${encodeURIComponent(Code)}&Barcode=${encodeURIComponent(Barcode)}` +
                        `&FileContent=${encodeURIComponent(FileContent)}&CategoryFk=${CategoryFK}` +
                        `&RubricCategoryFk=${CategoryFK}&Comment=${encodeURIComponent(Comment)}` +
                        `&DocumentTypeFk=${DocumentTypeFK}&OriginFileName=${encodeURIComponent(OriginFileName)}` +
                        `&FileUrl=${encodeURIComponent(FileUrl)}&IsDownload=${IsDownload}` +
                        `&Description=${encodeURIComponent(Description)}&StatusFk=${StatusFK}` +
                        `&TypeFk=${TypeFK}&InvHeaderCode=${encodeURIComponent(InvHeaderCode)}` +
                        `&ProjectCode=${encodeURIComponent(ProjectCode)}&ProjectInfoRequestCode=${encodeURIComponent(ProjectInfoRequestCode)}` +
                        `&PsdActivityCode=${encodeURIComponent(PsdActivityCode)}&PsdScheduleCode=${encodeURIComponent(PsdScheduleCode)}` +
                        `&QtnHeaderCode=${encodeURIComponent(QtnHeaderCode)}&ReqHeaderCode=${encodeURIComponent(ReqHeaderCode)}` +
                        `&RfqHeaderCode=${encodeURIComponent(RfqHeaderCode)}&PrjChangeCode=${encodeURIComponent(PrjChangeCode)}` +
                        `&QtoHeaderCode=${encodeURIComponent(QtoHeaderCode)}&StructureCode=${encodeURIComponent(StructureCode)}` +
                        `&ExpirationDate=${encodeURIComponent(ExpirationDate)}&ItwoSiteId=${encodeURIComponent(ItwoSiteId)}` +
                        `&CompanyCode=${encodeURIComponent(CompanyCode)}` +
                        `&UserDataLanguage=${encodeURIComponent(UserDataLanguage)}` +
                        `&ProjectDocumentId=${encodeURIComponent(ProjectDocumentId)}` +
                        `&CommentText=${encodeURIComponent(CommentText)}` +
                        `&LogOptions=${encodeURIComponent(LogOptions)}` +
                        `&RequestedCompanyId=${encodeURIComponent(RequestedCompanyId)}` +
                        `&RequestedPermissionClientId=${encodeURIComponent(RequestedPermissionClientId)}` +
                        `&RequestedSignedInCompanyId=${encodeURIComponent(RequestedSignedInCompanyId)}` +
                        `&FileArchiveDocId=${encodeURIComponent(FileArchiveDocId)}` +
                        `&Compress=${encodeURIComponent(CompressValue)}`;

                    for (var pair of formData.entries()) {
                        console.log(pair[0] + ', ' + pair[1]);
                    }
                    const response = await fetch(`${apiUrl}/PdfElement/generatepdf2?${queryString}`, {
                        method: 'POST',
                        body: formData,
                    });

                    if (!response.ok) {
                        throw new Error('Error saving PDF file. Please contact system administrator.' + response.body);
                    }

                    //
                } else {
                    alert('Error on loading canvas');
                }
            }
        }
        window.parent.postMessage('FinishButtonClicked', '*');
        setIsLoading(false);
    };


    useEffect(() => {
        const loadAllPdfs = async () => {
            setPdfLoaded(false);

            const loadPdf = async (doc) => {
                if (!doc) return;

                const token = doc.token;
                const requestedCompanyId = doc.RequestedCompanyId || 1;
                const requestedPermissionClientId = doc.RequestedPermissionClientId || 1;
                const requestedSignedInCompanyId = doc.RequestedSignedInCompanyId || 1;
                const requestedRoleId = doc.RequestedRoleId;
                const fileArchiveDocId = doc.FileArchiveDocId;
                const uid = doc.uid;

                const queryString = `token=${encodeURIComponent(token)}&uid=${encodeURIComponent(uid)}&requestedCompanyId=${requestedCompanyId}&requestedPermissionClientId=${requestedPermissionClientId}` +
                    `&requestedRoleId=${encodeURIComponent(requestedRoleId)}&requestedSignedInCompanyId=${encodeURIComponent(requestedSignedInCompanyId)}&fileArchiveDocId=${encodeURIComponent(fileArchiveDocId)}`;

                try {
                    const response = await fetch(`${apiUrl}/PdfElement/GetPdfFromService?${queryString}`);
                    if (!response.ok) {
                        throw new Error('PDF yüklenirken bir hata oluştu');
                    }
                    const data = await response.blob();
                    const fileURL = URL.createObjectURL(data);
                    return fileURL;
                } catch (error) {
                    console.error('PDF yükleme hatası:', error);
                    throw error;
                }
            };

            try {
                const pdfPromises = documents.map(doc => loadPdf(doc));
                const pdfUrls = await Promise.all(pdfPromises);

                setPdfFileUrls(pdfUrls.filter(url => url)); // Boş olmayan URL'leri ekle
            } catch (error) {
                console.error('PDF yüklemeleri sırasında bir hata oluştu:', error);
            } finally {
                setPdfLoaded(true);
            }
        };

        if (documents.length > 0) {
            loadAllPdfs();
        } else {
            setPdfLoaded(true); // Döküman yoksa yükleme işlemi tamamlandı
        }
    }, [documents]);


    useEffect(() => {
        const updateScale = () => {
            const screenWidth = window.innerWidth;
            const scale = screenWidth < 768 ? 0.6 : 1.0;
            setScaleValue(scale);
        };

        window.addEventListener('resize', updateScale);
        updateScale(); // İlk yüklemede ölçeklemeyi ayarla

        return () => window.removeEventListener('resize', updateScale);
    }, []);

    //useEffect(() => {
    //    console.log(pdfRef);
    //}, [pdfRef]);

    const updatePayload = (newItem) => {
        // Öncelikle, newItem'in id'sine sahip bir öğe payload içinde var mı diye kontrol et
        const itemIndex = payload.findIndex(item => item.id === newItem.id);

        if (itemIndex !== -1) {
            // Eğer varsa, o öğeyi yeni bilgilerle güncelle
            const newPayload = payload.map((item, index) => {
                if (index === itemIndex) {
                    return { ...item, ...newItem }; // Var olan öğeyi newItem ile güncelle
                }
                return item;
            });
            setPayload(newPayload);
            console.log(newPayload);
        } else {
            // Eğer yoksa, newItem'i payload'a yeni bir öğe olarak ekle
            setPayload([...payload, newItem]);
            console.log(newItem);
        }
    };

    const [, drop] = useDrop(() => ({
        accept: ['IMAGE', 'TEXT', 'DRAWING'],
        drop: (item, monitor) => {
            console.log(item.left);
            console.log(item.top);
            const delta = monitor.getDifferenceFromInitialOffset();
            const left = Math.round(item.left + delta.x);
            const top = Math.round(item.top + delta.y);
            updatePayload({ ...item, left, top });
        },
    }), [payload]);

    const handleEnlarge = () => {
        if (activeElementId == null) return;
        setPayload(payload.map(item => {
            if (item.id === activeElementId) {
                if (item.type === 'TEXT') {
                    // Eğer öğe metin tipindeyse, font boyutunu +1 artır
                    return { ...item, fontSize: item.fontSize + 1 };
                } else {
                    // Diğer öğe türleri için genişlik ve yüksekliği %20 artır
                    return { ...item, width: item.width * 1.2, height: item.height * 1.2 };

                    setWidthToLocalStorage(item.width * 1.2);
                }
            }
            return item;
        }));
    };

    const handleShrink = () => {
        if (activeElementId == null) return;
        setPayload(payload.map(item => {
            if (item.id === activeElementId) {
                if (item.type === 'TEXT') {
                    // Eğer öğe metin tipindeyse, font boyutunu %20 azalt
                    return { ...item, fontSize: item.fontSize - 1 };
                } else {
                    // Diğer öğe türleri için genişlik ve yüksekliği %20 azalt
                    return { ...item, width: item.width * 0.8, height: item.height * 0.8 };

                    setWidthToLocalStorage(item.width * 0.8);
                }
            }
            return item;
        }));
    };

    const handleDuplicate = () => {
        if (activeElementId == null) return;
        const itemToDuplicate = payload.find(item => item.id === activeElementId);
        if (!itemToDuplicate) return;
        const newItem = { ...itemToDuplicate, id: Date.now() }; // Yeni ID atama
        setPayload([...payload, newItem]);
    };

    const handleDelete = () => {
        if (activeElementId == null) return;
        setPayload(payload.filter(item => item.id !== activeElementId));
        setActiveElementId(null); // Aktif elementi sıfırla
    };

    const getWidthFromLocalStorage = () => {
        const storedWidth = localStorage.getItem('signatureWidth');
        return storedWidth ? parseInt(storedWidth, 10) : 150; // Varsayılan olarak 150 kullanılır
    };

    const setWidthToLocalStorage = (width) => {
        localStorage.setItem('signatureWidth', width);
    };

    const addSignatureToPdf = (signatureUrl) => {
        if (pdfRef.current) {
            const rect = pdfRef.current.getBoundingClientRect();
            // `rect` ile işlemlerinizi yapın

            const relativeLeft = rect.left; // Örnek bir hesaplama, gerçekte nasıl ayarlamak istediğinize bağlı
            const relativeTop = rect.top; // Örnek bir hesaplama, gerçekte nasıl ayarlamak istediğinize bağlı

            // Sayfa içindeki mutlak pozisyonları hesaplamak için kaydırma miktarını ekleyin
            const absoluteLeft = rect.left + window.scrollX;
            const absoluteTop = rect.top + window.scrollY;

            const initialWidth = getWidthFromLocalStorage();
            console.log(absoluteLeft);
            console.log(absoluteTop);
            updatePayload({
                id: uuidv4(),
                type: 'IMAGE',
                src: signatureUrl,
                left: absoluteLeft, // Başlangıç konumu, gerçek uygulamada kullanıcı tarafından belirlenebilir
                top: absoluteTop, // Başlangıç konumu, gerçek uygulamada kullanıcı tarafından belirlenebilir
                width: initialWidth, // İmzanın genişliği
                page: currentPage, // İmzanın ekleneceği sayfa
                selectedIndex: selectedIndex,
            });
        }
    };

    const addIconToPdf = async (iconName) => {
        const iconMappings = {
            'check': '/icons/icons8-check-48.png', // 'check' ikonu için resim yolu
            'times': '/icons/icons8-cross-mark-48.png', // 'times' ikonu için resim yolu
            'exclamation': '/icons/icons8-exclamation-mark-48.png', // 'exclamation' ikonu için resim yolu
            'question': '/icons/icons8-question-mark-48.png', // 'question' ikonu için resim yolu
            // Daha fazla ikon eklenebilir.
        };

        if (pdfRef.current && iconMappings[iconName]) {
            const response = await fetch(iconMappings[iconName]);
            const blob = await response.blob();
            const reader = new FileReader();

            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;

                const rect = pdfRef.current.getBoundingClientRect();
                const relativeLeft = rect.left;
                const relativeTop = rect.top;

                // Sayfa içindeki mutlak pozisyonları hesaplamak için kaydırma miktarını ekleyin
                const absoluteLeft = rect.left + window.scrollX;
                const absoluteTop = rect.top + window.scrollY;

                updatePayload({
                    id: uuidv4(),
                    type: 'IMAGE',
                    src: base64data, // İkonun base64 formatında kaydedilmiş hali
                    left: absoluteLeft,
                    top: absoluteTop,
                    width: 30, // İkonun genişliği, bu değer ikonunuzun boyutuna bağlı olarak değişebilir
                    page: currentPage,
                    selectedIndex: selectedIndex,
                });
            };
        }
    };

    function getZoomLevel() {
        return Math.round(window.devicePixelRatio * 100);
    }

    const deleteStamp = async (stampId) => {
        // API'den gelen token ve uid değerlerini al
        //const queryParams = new URLSearchParams(window.location.search);
        const token = documents[selectedIndex].token;
        const uid = documents[selectedIndex].uid;

        const response = await fetch(`${apiUrl}/PdfElement/DeleteStamp?token=${encodeURIComponent(token)}&uid=${encodeURIComponent(uid)}&stampid=${stampId}`, {
            method: 'POST', // Backend'de belirtildiği üzere HTTP POST metodu kullanılır
        });

        if (response.ok) {
            console.log('Stamp deleted successfully');
            // Silme işlemi başarılı olduysa, UI'dan da mührü kaldır
            setStamps(currentStamps => currentStamps.filter(stamp => stamp.id !== stampId));
        } else {
            console.error('Failed to delete stamp', await response.text());
        }
    };

    useEffect(() => {
        if (pdfLoaded && signatureLoaded) {
            setIsLoading(false);
            console.log('done');
        }
    }, [pdfLoaded, signatureLoaded]);

    useEffect(() => {
        if (signatureUrl) {
            setSignatureLoaded(true);
        }
    }, [signatureUrl]);

    useEffect(() => {
        if (pdfRef.current) {
            console.log(pdfRef.current.getBoundingClientRect());
            const rect = pdfRef.current.getBoundingClientRect();
            setCanvas({ width: rect.width, height: rect.height });
        }
    }, [pdfRef.current]);

    useEffect(() => {
        if (pdfFileUrls) {
            setPdfLoaded(true);
        }
    }, [pdfFileUrls]);

    const handleChangeFile = (event) => {
        //const selectedIndex = event.target.value;
        //const selectedDocument = documents[selectedIndex];x
        const selectedIndex = parseInt(event.target.value, 10);
        setSelectedIndex(selectedIndex);
        setCurrentPage(1);
        setActiveElementId(null);
        //console.log('Selected Document:', selectedDocument);
    };

    return (<>{isLoading && <Loader />}
        <div style={{ padding: "5px !important" }} className={`row d-flex sm-column w-100 p-2 ${isLoading ? 'blur' : ''}`} ref={drop}>
            <div className="col-sm-4 col-12 card bg-light p-0">
                <div className="card-header">
                    <div className="btn-group" role="group" aria-label="Top Tool Tabs">
                        <button type="button" className={`btn ${activeTab === "drawing" ? "btn-primary" : "btn-secondary"}`} onClick={() => setActiveTab("drawing")} title={texts.draw}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                        <button type="button" className={`btn ${activeTab === "image" ? "btn-primary" : "btn-secondary"}`} onClick={() => setActiveTab("image")} title={texts.upload}>
                            <FontAwesomeIcon icon={faImage} />
                        </button>
                        <button type="button" className={`btn ${activeTab === "text" ? "btn-primary" : "btn-secondary"}`} onClick={() => setActiveTab("text")} title={texts.text}>
                            <FontAwesomeIcon icon={faFont} />
                        </button>
                        <button type="button" className={`btn ${activeTab === "signature" ? "btn-primary" : "btn-secondary"}`} onClick={() => setActiveTab("signature")} title={texts.sign}>
                            <FontAwesomeIcon icon={faSignature} />
                        </button>
                        <button type="button" className={`btn ${activeTab === "stamps" ? "btn-primary" : "btn-secondary"}`} onClick={() => setActiveTab("stamps")} title={texts.stamp}>
                            <FontAwesomeIcon icon={faStamp} />
                        </button>
                        <button type="button" className={`btn ${activeTab === "tools" ? "btn-primary" : "btn-secondary"}`} onClick={() => setActiveTab("tools")} title={texts.tools}>
                            <FontAwesomeIcon icon={faTools} />
                        </button>
                    </div>
                </div>
                {/*<div className="card-header">*/}
                {/*    <div className="btn-group" role="group" aria-label="Top Tool Tabs">*/}
                {/*        <button type="button" className={`btn ${activeTab === "drawing" ? "btn-primary" : "btn-secondary"}`} onClick={() => setActiveTab("drawing")}>{texts.draw}</button>*/}
                {/*        <button type="button" className={`btn ${activeTab === "image" ? "btn-primary" : "btn-secondary"}`} onClick={() => setActiveTab("image")}>{texts.upload}</button>*/}
                {/*        <button type="button" className={`btn ${activeTab === "text" ? "btn-primary" : "btn-secondary"}`} onClick={() => setActiveTab("text")}>{texts.text}</button>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="card-header">*/}
                {/*    <div className="btn-group" role="group" aria-label="Bottom Tool Tabs">*/}
                {/*        <button type="button" className={`btn ${activeTab === "signature" ? "btn-primary" : "btn-secondary"}`} onClick={() => setActiveTab("signature")}>{texts.sign}</button>*/}
                {/*        <button type="button" className={`btn ${activeTab === "stamps" ? "btn-primary" : "btn-secondary"}`} onClick={() => setActiveTab("stamps")}>{texts.stamp}</button>*/}
                {/*        <button type="button" className={`btn ${activeTab === "tools" ? "btn-primary" : "btn-secondary"}`} onClick={() => setActiveTab("tools")}>{texts.tools}</button>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="card-body">
                    {activeTab === "drawing" && pdfRef && <DrawingTool updatePayload={updatePayload} pdfRef={pdfRef} currentPage={currentPage} numPdfPages={numPdfPages} saveSignatureToDatabase={saveSignatureToDatabase} selectedIndex={selectedIndex} />}
                    {activeTab === "image" && <ImageUploader updatePayload={updatePayload} pdfRef={pdfRef} currentPage={currentPage} numPdfPages={numPdfPages} saveSignatureToDatabase={saveSignatureToDatabase} selectedIndex={selectedIndex} />}
                    {activeTab === "text" && <TextTool updatePayload={updatePayload} pdfRef={pdfRef} currentPage={currentPage} numPdfPages={numPdfPages} selectedIndex={selectedIndex} />}
                    {activeTab === "signature" && (
                        signatureUrl ? (
                            <>
                                <img className="mb-2" src={signatureUrl} alt="Signature" style={{ maxWidth: '100%', height: 'auto' }} /><br />
                                <button className="btn btn-success mt-2" onClick={() => addSignatureToPdf(signatureUrl)}>{texts.send}</button>
                            </>
                        ) : (
                            <p>{texts.signatureempty}</p>
                        )
                    )}
                    {activeTab === "stamps" && (
                        stamps.length > 0 ? (
                            <div className="row">
                                {stamps.map((stamp, index) => (
                                    <div key={stamp.id} className="col-6 mb-2">
                                        <img src={stamp.imageUrl} alt={`Stamp ${index + 1}`} style={{ maxWidth: '100%', height: 'auto' }} />
                                        <button className="btn btn-success m-1" onClick={() => addSignatureToPdf(stamp.imageUrl)}>{texts.send}</button>
                                        <button className="btn btn-danger m-1" onClick={() => deleteStamp(stamp.id)}><FontAwesomeIcon icon={faTrashAlt} /></button>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>{texts.stampsempty}</p>
                        )
                    )}
                    {activeTab === "tools" && (
                        <div className="row">
                            <div className="col-3 mb-2">
                                <button className="btn btn-light m-1" onClick={() => addIconToPdf('check')}>
                                    <img width="24" src="/icons/icons8-check-48.png" alt="Check" />
                                </button>
                            </div>
                            <div className="col-3 mb-2">
                                <button className="btn btn-light m-1" onClick={() => addIconToPdf('times')}>
                                    <img width="24" src="/icons/icons8-cross-mark-48.png" alt="Times" />
                                </button>
                            </div>
                            <div className="col-3 mb-2">
                                <button className="btn btn-light m-1" onClick={() => addIconToPdf('exclamation')}>
                                    <img width="24" src="/icons/icons8-exclamation-mark-48.png" alt="Exclamation" />
                                </button>
                            </div>
                            <div className="col-3 mb-2">
                                <button className="btn btn-light m-1" onClick={() => addIconToPdf('question')}>
                                    <img width="24" src="/icons/icons8-question-mark-48.png" alt="Question" />
                                </button>
                            </div>
                        </div>
                    )}

                    <div style={{ display: activeElementId == undefined ? 'none' : '' }}>
                        <button data-draggable="item" className="btn btn-primary m-1" onClick={handleEnlarge}>+</button>
                        <button data-draggable="item" className="btn btn-primary m-1" onClick={handleShrink}>-</button>
                        <button data-draggable="item" className="btn btn-primary m-1" onClick={handleDuplicate}><FontAwesomeIcon icon={faClone} /></button>
                        <button data-draggable="item" className="btn btn-danger m-1" onClick={handleDelete}><FontAwesomeIcon icon={faTrashAlt} /></button>
                    </div>
                    <br />
                    {/*<Button className="btn btn-secondary mt-2" onClick={() => testLocations()}>test</button>*/}
                    <button id="finish_button" style={{ visibility: 'hidden' }} className="btn btn-secondary mt-2" onClick={() => sendPdfWithElements(payload.filter(doc => doc.selectedIndex === selectedIndex))}>{texts.finish}</button>
                </div>
            </div>
            <div className="col-sm-8 col-12">
                <div className="row d-flex sm-column w-100 p-2">
                    <div className="col-8">
                        <select onChange={handleChangeFile}>
                            {documents.map((a, index) => (
                                <option key={index} value={index}>
                                    {a.OriginFileName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-4">
                        <PdfNavigation
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            numPdfPages={numPdfPages}
                        />
                    </div>
                </div>
                {canvas.width > 0 && <div style={{ zIndex: '1', width: canvas.width }} ref={pdfRef} className="pdf-viewer">
                    {pdfFileUrls && <Document file={pdfFileUrls[selectedIndex]} onLoadSuccess={({ numPages }) => setNumPdfPages(numPages)}>
                        <Page pageNumber={currentPage} scale={scaleValue} />
                    </Document>}
                    {payload.filter(item => item.page === currentPage && item.selectedIndex === selectedIndex).map((item, index) => {
                        if (item.type === 'IMAGE') {
                            return <DraggableImage key={index} {...item} updatePayload={updatePayload}
                                setActiveElementId={setActiveElementId}
                                activeElementId={activeElementId} />;
                        } else if (item.type === 'TEXT') {
                            return <DraggableText key={index} {...item} updatePayload={updatePayload}
                                setActiveElementId={setActiveElementId}
                                activeElementId={activeElementId} />;
                        } else if (item.type === 'DRAWING') {
                            return <Drawing
                                key={index}
                                {...item} updatePayload={updatePayload}
                                setActiveElementId={setActiveElementId}
                                activeElementId={activeElementId}
                            />;
                        }
                        return null;
                    })}
                </div>
                }
            </div>
            {/*<div className="footer">*/}
            {/*    <button className="btn btn-secondary" onClick={handleAbort}>Abort</button>*/}
            {/*    <button className="btn btn-secondary" onClick={handleFinish}>Ok</button>*/}
            {/*</div>*/}
        </div></>
    );
};

export default Main;