import React from 'react';
import { useDrag } from 'react-dnd';

const DraggableText = ({ id, text, left, top, fontSize, updatePayload, activeElementId, setActiveElementId }) => {
    const [, drag] = useDrag(() => ({
        type: 'TEXT',
        item: () => {
            // S�r�kleme ba�lad���nda aktif element ID'sini g�ncelle
            setActiveElementId(id);
            // S�r�kleme ��esinin ba�lang�� de�erlerini d�nd�r
            return { id, type: 'TEXT', left, top };
        },
        end: (item, monitor) => {
            // S�r�kleme i�lemi bitti�inde, yeni pozisyonu g�ncelle
            const delta = monitor.getDifferenceFromInitialOffset();
            if (delta) {
                const newLeft = Math.round(item.left + delta.x);
                const newTop = Math.round(item.top + delta.y);
                updatePayload({ ...item, left: newLeft, top: newTop });
            }
        },
    }), [id, left, top, updatePayload]);

    return (
        <div data-draggable="item"
            onClick={() => setActiveElementId(id)}
            ref={drag}
            style={{
                position: 'absolute',
                left: `${left}px`,
                top: `${top}px`,
                fontSize: `${fontSize}px`,
                cursor: 'move',
                border: id === activeElementId ? '3px dashed green' : '2px dashed gray',
                padding: '4px',
                borderRadius: '5px',
                zIndex:'999',
                maxWidth: '600px', // Metin alan�n�n geni�li�ini s�n�rla
                wordWrap: 'break-word', // Uzun metinleri alt sat�ra ta��
                backgroundColor: 'rgba(255, 255, 255, 0)' // Metin okunabilirli�ini art�rmak i�in
            }}
        >
            {text}
        </div>
    );
};

export default DraggableText;