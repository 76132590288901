import React from 'react';
import { useDrag } from 'react-dnd';

const Drawing = ({ id, src, left, top, width, height, updatePayload, activeElementId, setActiveElementId }) => {
    // useDrag hook'u ile s�r�kleme i�levselli�ini ayarla
    const [, drag] = useDrag(() => ({
        type: 'DRAWING', // S�r�kleme tipi
        item: () => {
            // S�r�kleme ba�lad���nda aktif element ID'sini g�ncelle
            setActiveElementId(id);
            // S�r�kleme ��esinin ba�lang�� de�erlerini d�nd�r
            return { id, type: 'DRAWING', left, top };
        },
        end: (item, monitor) => {
            // S�r�kleme i�lemi bitti�inde pozisyonu g�ncelle
            const delta = monitor.getDifferenceFromInitialOffset();
            if (delta) {
                const newLeft = Math.round(item.left + delta.x);
                const newTop = Math.round(item.top + delta.y);
                updatePayload({ ...item, left: newLeft, top: newTop });
            }
        },
    }), [id, left, top, updatePayload]);

    // �izim g�r�nt�s�n� g�ster
    return (
        <div data-draggable="item" ref={drag} style={{
            position: 'absolute', left: `${left}px`, top: `${top}px`, border: id === activeElementId ? '3px dashed green' : '2px dashed gray', // Aktif element i�in farkl� bir stil,
            borderRadius: '5px', zIndex: '999', cursor: 'move',
        }}
            onClick={() => setActiveElementId(id)}
        >
            <img src={src} style={{ width: width, height: height }} alt="Drawing" />
        </div>
    );
};

export default Drawing;
