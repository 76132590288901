import React from 'react';
import { useDrag } from 'react-dnd';

const DraggableImage = ({ id, src, left, top, width, height, updatePayload, activeElementId, setActiveElementId }) => {
    const [, drag] = useDrag(() => ({
        type: 'IMAGE',
        item: () => {
            // S�r�kleme ba�lad���nda aktif element ID'sini g�ncelle
            setActiveElementId(id);
            // S�r�kleme ��esinin ba�lang�� de�erlerini d�nd�r
            return { id, type: 'IMAGE', left, top };
        },
        end: (item, monitor) => {
            // S�r�kleme i�lemi bitti�inde, yeni pozisyonu g�ncelle
            const delta = monitor.getDifferenceFromInitialOffset();
            if (delta) {
                const newLeft = Math.round(item.left + delta.x);
                const newTop = Math.round(item.top + delta.y);
                updatePayload({ ...item, left: newLeft, top: newTop });
            }
        },
    }), [id, left, top, updatePayload]);

    return (
        <img data-draggable="item" onClick={() => setActiveElementId(id)} ref={drag} src={src} style={{
            position: 'absolute',
            left: `${left}px`,
            top: `${top}px`,
            width: `${width}px`,
            height: `${height}px`,
            cursor: 'move',
            border: id === activeElementId ? '3px dashed green' : '2px dashed gray',
            borderRadius: '5px', zIndex: '999',
        }} alt="Draggable" />
    );
};

export default DraggableImage;
