import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const TextTool = ({ updatePayload, pdfRef, currentPage, numPdfPages, selectedIndex }) => {
    const [text, setText] = useState('');
    const [fontSize, setFontSize] = useState(16); // Varsayılan font boyutu
    const queryParams = new URLSearchParams(window.location.search);
    const language = queryParams.get('language');

    // Dil koduna göre metinleri ayarla
    let texts;
    switch (language) {
        case '1': // İngilizce
            texts = {
                send: 'Send',
                fontsize: 'Font Size: '
            };
            break;
        case '2': // Almanca
            texts = {
                send: 'Senden',
                fontsize: 'Schriftgröße: '
            };
            break;
        default: // Varsayılan dil İngilizce
            texts = {
                send: 'Send',
                fontsize: 'Font Size: '
            };
    }

    const handleTextChange = (e) => {
        setText(e.target.value);
    };

    const handleFontSizeChange = (e) => {
        setFontSize(e.target.value);
    };

    const handleSubmit = () => {
        if (pdfRef.current) {
            const rect = pdfRef.current.getBoundingClientRect();
            // `rect` ile işlemlerinizi yapın

            const relativeLeft = rect.left; // Örnek bir hesaplama, gerçekte nasıl ayarlamak istediğinize bağlı
            const relativeTop = rect.top; // Örnek bir hesaplama, gerçekte nasıl ayarlamak istediğinize bağlı

            if (text.trim() !== '') {
                const newItem = {
                    id: uuidv4(),
                    type: 'TEXT',
                    text: text,
                    left: relativeLeft, // Başlangıç konumu örneği
                    top: relativeTop, // Başlangıç konumu örneği
                    fontSize: fontSize,
                    page: currentPage,
                    selectedIndex: selectedIndex
                };
                updatePayload(newItem);
                // Metin ve font boyutunu sıfırla
                //setText('');
                //setFontSize(16);
            }
        }
    };

    return (
        <div>
            <textarea
                value={text}
                onChange={handleTextChange}
                placeholder="Text Input"
                style={{ width: '100%', height: '100px', marginBottom: '10px' }}
            />
            <div className='mb-1'>
                <label>{ texts.fontsize }</label>
                <input
                    type="number"
                    value={fontSize}
                    onChange={handleFontSizeChange}
                    min="8"
                    max="72"
                />
            </div>
            <button className='mt-7 btn btn-success' onClick={handleSubmit}>{ texts.send }</button>
        </div>
    );
};

export default TextTool;
